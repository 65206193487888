<template>
  <div>
    <h3 class="text-center">在刊资源占比分布</h3>
    <div style="width:290px;height:200px;" id="countIssueNumberReport"></div>
    <Table stripe size="small" :data="countIssueNumberData" :columns="countIssueNumberColumn"></Table>

    <h3 class="text-center p-t-20 p-b-5">在刊品牌清单</h3>
    <Table stripe size="small" :data="realTimeBrandData" :columns="realTimeBrandColumn"></Table>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { countIssueNumberGbFeature, countIssueNumberGb } from '@/api/dw/maintain'

export default {
  data () {
    return ({
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: this.$store.getters.token.userInfo.companyId,
      dataSourcePie: null,

      countIssueNumberColumn: [
        {
          title: '类型',
          width: 90,
          render: (h, params) => {
            return h('a',
              {
                style: {
                  color: this.chooseContractFeature === params.row.id ? '#ff9900' : '#2D8cF0'
                },
                on: {
                  click: () => {
                    this.loadBrandList(params.row.id)
                  }
                }
              }, params.row.name
            )
          }
        },
        { title: '在刊点位数', align: 'center', key: 'number' },
        { title: '在刊品牌数', align: 'center', key: 'value' }
      ],
      countIssueNumberData: [],

      realTimeBrandColumn: [
        { title: '品牌名称', key: 'name' },
        { title: '在刊点位数', align: 'center', width: 100, key: 'number' }
      ],
      realTimeBrandData: [],
      chooseContractFeature: null
    })
  },
  mounted () {
    if (this.companyId === this.publisherId) {
      this.companyId = 0
    }
    this.loadCountIssue()
    this.loadBrandList(null)
  },
  methods: {
    loadCountIssue () {
      const queryModel2 = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        date: this.publishDate
      }

      countIssueNumberGbFeature(queryModel2).then(res => {
        this.countIssueNumberData = res

        const reportData = res.map(element => {
          return {
            value: element.rate,
            name: element.name
          }
        })
        this.initChart(reportData)
      })
    },
    loadBrandList (contractFeature) {
      if (this.chooseContractFeature === contractFeature) {
        this.chooseContractFeature = null
      } else {
        this.chooseContractFeature = contractFeature
      }

      const queryModel1 = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        contractFeature: this.chooseContractFeature,
        date: this.publishDate,
        gbType: '2' // 统计纬度 1:行业; 2:品牌
      }

      countIssueNumberGb(queryModel1).then(res => {
        this.realTimeBrandData = res
      })
    },
    initChart (reportData) {
      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('countIssueNumberReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('countIssueNumberReport'))
          const option = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '在刊资源占比分布',
                type: 'pie',
                radius: ['70%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    formatter: '{d}%\n{b}',
                    color: '#fff'
                  }
                },
                labelLine: {
                  show: false
                },
                data: reportData
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)

          // 默认选中第一项
          let index = 0
          const _self = this
          _self.dataSourcePie.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: 0
          }) // 设置默认选中高亮部分
          _self.dataSourcePie.on('mouseover', function (e) {
            if (e.dataIndex !== index) {
              _self.dataSourcePie.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
              })
            }
          })
          _self.dataSourcePie.on('mouseout', function (e) {
            index = e.dataIndex
            _self.dataSourcePie.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: e.dataIndex
            })
          })
        }
      })
    }
  },
  computed: {
    publishDate () {
      return this.$store.state.statistic.publishDate
    }
  },
  watch: {
    publishDate () {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.loadCountIssue()
      this.loadBrandList(null)
    }
  }
}
</script>
